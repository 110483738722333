import axios from 'axios'

const Comisiones = {
    listaPendientes(id_cedis){
        return axios.get(`comisiones/pendientes/${id_cedis}`)
    },
    liquidarPedidos(pedidos){
        return axios.post(`comisiones/liquidar`, pedidos)
    },
}

export default Comisiones
