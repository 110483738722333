<template>
    <section class="d-flex flex-column overflow-auto pt-3 custom-scroll" style="height:100%">
        <div class="row my-3 px-3">
            <div class="col-auto d-middle">
                <i class="icon-cedis f-18 position-relative text-general" style="bottom: 2px" />
                <el-select v-model="id_cedis" class="w-100" size="small">
                    <el-option v-for="item in cedis_calculo" :key="item.id" :label="item.nombre" :value="item.id" />
                </el-select>
            </div>
            <div class="col-auto d-middle ml-auto">
                <p class="f-15 text-general fr-light">{{ this.pedidosSeleccionados.length }} pedidos seleccionados</p>
                <button class="btn btn-sm text-white ml-2 br-8 w-122px fr-light f-14 bg-general" @click="abrirModalLiquidar">
                    Liquidar
                </button>
            </div>
        </div>
        <div v-for="(item, index) in vendedores" :key="index" class="mb-4">
            <tabla-general :data="item.pedidos" alto="auto" size="small" class-header="text-general f-16" :mostrar-buscador="false" @seleccion="seleccionarPedidos($event,item.id)">
                <template slot="cabecera-izquierda">
                    <div class="d-middle px-3 py-2">
                        <div class="d-middle ml-2">
                            <img :src="item.foto" alt="" class="bg-general wh-40 rounded-circle" />
                            <div class="lh-19 ml-2">
                                <p class="f-500 text-general">{{ item.propietario_nombre }}</p>
                                <p class="f-14 ucfirst bg-general text-white br-3 px-1">{{ item.nombre_tipo }}</p>
                                <p class="f-14 text-general2">{{ item.pedidos.length }} pedidos</p>
                            </div>
                        </div>
                    </div>
                </template>
                <template slot="cabecera-derecha">
                    <div class="d-middle px-3 py-2 ml-auto">
                        <div class="d-middle ml-2">
                            <div class="lh-19 ml-2">
                                <p class="f-500 text-general">$ {{ agregarSeparadoresNumero(item.utilidad_total,2) }}</p>
                                <p class="f-14 text-general2">Utilidad Total</p>
                            </div>
                        </div>
                        <div class="d-middle ml-5">
                            <div class="lh-19 ml-2">
                                <p class="f-500 text-general">$ {{ agregarSeparadoresNumero(item.comision_total,2) }}</p>
                                <p class="f-14 text-general2">Comision Total</p>
                            </div>
                        </div>
                    </div>
                </template>
                <el-table-column type="selection" width="55" align="center" />
                <el-table-column label="ID Pedido" align="center" min-width="150">
                    <template slot-scope="{row}">
                        <p class="text-general text-center">{{ row.id }}</p>
                    </template>
                </el-table-column>
                <el-table-column label="Valor" align="center" min-width="150">
                    <template slot-scope="{row}">
                        <p class="text-general text-center">{{ convertMoney(row.valor_final,row.idm_moneda) }}</p>
                    </template>
                </el-table-column>
                <el-table-column prop="cant_productos" label="Productos" align="center" min-width="150" />
                <el-table-column prop="cliente" label="Cliente" min-width="150" />
                <el-table-column prop="valor" label="Utilidad" align="center" min-width="150">
                    <template slot-scope="{row}">
                        <p v-if="item.forma_pago == 1" class="text-general text-center">{{ convertMoney(row.utilidad_valor, row.idm_moneda) }}</p>
                        <p v-else class="text-general text-center">No aplica</p>
                    </template>
                </el-table-column>
                <el-table-column label="Comisión" align="center" min-width="150">
                    <template slot-scope="{row}">
                        <p class="text-general text-center">{{ convertMoney(row.comision, row.idm_moneda) }}</p>
                    </template>
                </el-table-column>
                <el-table-column prop="dias_pago" label="# días despues de limite de pago" align="center" min-width="150" />
            </tabla-general>
        </div>
        <div v-if="vendedores.length === 0" class="col-12">
            <div class="br-5 mx-auto d-middle-center bg-whitesmoke mt-5">
                <img :src="`/img/otros/no_datos.svg`" alt="" style="width:500px;height:400px;" />
            </div>
            <div class="row d-middle-center mt-2">
                <p>Los pedidos deben estar Finalizados y los creditos pagados para que salgan en esta lista</p>
            </div>
        </div>
        <!-- Partials -->
        <modal ref="refModalLiquidar" titulo="Liquidar pedidos" tamano="modal-md" no-aceptar adicional="Liquidar" @adicional="liquidarPedidos">
            <p class="my-2 text-center">¿Deseas liquidar estos pedidos?</p>

            <div class="row  mx-2">
                <p class="text-center"><b>Numero pedidos a liquidar:</b> {{ this.pedidosSeleccionados.length }}</p>
            </div>
            <div class="row mx-2">
                <p class="text-center"><b>Valor total pagar:</b> {{ agregarSeparadoresNumero(_.sumBy(this.pedidosSeleccionados, 'comision'),2) }}</p>
            </div>
            <div class="row mx-2 mt-2">
                <el-input v-model="comentario" type="textarea" :rows="3" placeholder="Comentario..." class="w-100" maxlength="500" show-word-limit />
            </div>
        </modal>
    </section>
</template>

<script>
import Comisiones from '~/services/comisiones/comisiones'
export default {
    data(){
        return{
            comentario: '',
            dataTable2:[
                {
                    id: 1,
                    pedido: '3546846',
                    valor: 600000,
                    productos: 3,
                    cliente: 'Carlos Aguilar',
                    utilidad: 587000,
                    comision: 245000,
                    dias: 10,
                },
            ],
            id_cedis:0,
            vendedores:[],
            arraySeleccionados:{},
            pedidosSeleccionados:[]
        }
    },
    watch:{
        id_cedis(val){
            this.getTabla()
        }
    },
    mounted(){
        if(this.id_cedis != null){
            this.getTabla()
        }
    },
    methods: {
        async getTabla(){
            try {
                const {data} = await Comisiones.listaPendientes(this.id_cedis)

                this.vendedores = data.vendedores
            } catch (e){
                this.error_catch(e)
            }
        },
        seleccionarPedidos(items, id){
            //console.log(items, id)

            this.arraySeleccionados[id] = items

            //console.log(this.arraySeleccionados)
            this.pedidosSeleccionados = []
            for (const key in this.arraySeleccionados){
                const pedidos = this.arraySeleccionados[key];
                const mapPedidos = _.map(pedidos, p => ({
                    idPedido:p.id,
                    comision:p.comision
                }));
                this.pedidosSeleccionados = [...this.pedidosSeleccionados,...mapPedidos]

            }

            //console.log(this.pedidosSeleccionados)

        },
        /**/
        abrirModalLiquidar(){
            this.$refs.refModalLiquidar.toggle()
        },
        async liquidarPedidos(){

            try {
                if(this.pedidosSeleccionados.length == 0) return
                if(this.comentario.length == 0) return this.notificacion('','El comentario es requerido','warning')

                const form = {
                    comentario:this.comentario,
                    pedidos:this.pedidosSeleccionados
                }

                const {data} = await Comisiones.liquidarPedidos(form)
                this.$refs.refModalLiquidar.toggle()
                this.getTabla()

            } catch (error){
                this.error_catch(error)
            }
        }
        /**/
    }
}
</script>
<style scoped>
.boton-excel {
    border: 0px;
    background: transparent;
    color: #fff;
}
</style>
